<template lang="pug">
    section#industrializacao
        .wrapper
            h3 {{contents.content.title}}
            .row(v-for="row in contents.content.rows")
                .production(v-for="production in row")
                    .container
                        span.title {{production.title}}
                        ul
                            li(v-for="city in production.cities")
                                span.city {{city.name}}
                                span.prod {{city.value}}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "section-industrializacao",
    props,
}
</script>

<style lang="stylus" scoped src="./Industrializacao.styl"></style>